
import { IAvailablePromoPackage } from "@/interfaces/mlm";
import { dispatchGetUserProfile } from "@/store/main/actions";
import { readUserProfile } from "@/store/main/getters";
import {
  dispatchGetAvailablePromoPackages,
  dispatchSubmitCreatePromocode,
} from "@/store/mlm/actions";
import { Component, Vue, Prop } from "vue-property-decorator";
import {IUserProfile} from "@/interfaces";

@Component({
  components: {},
})
export default class GiveAGiftModal extends Vue {
  // @ts-ignore
  @Prop({ required: true, default: false }) public readonly modalShow: boolean;
  public packages: IAvailablePromoPackage[] = [];
  public selectedPackage: IAvailablePromoPackage | null = null;
  public loadingState: any = {
    packages: true,
    submit: false,
  };
  public isModalClosed: boolean = false;
  public promocode: string | null = null;
  public user: IUserProfile | null = null;


  public async mounted() {
    this.user = readUserProfile(this.$store);
    this.packages = await dispatchGetAvailablePromoPackages(this.$store);
    if (this.packages.length > 0) {
      this.selectedPackage = this.packages[0];
    }
    this.loadingState.packages = false;
  }

  public async handleCreatePromo() {
    if(this.loadingState.submit) return;
    if (!this.selectedPackage) {
      return null;
    }
    this.loadingState.submit = true;
    this.promocode = await dispatchSubmitCreatePromocode(this.$store, {
      promocode: this.selectedPackage.id,
      standart: "TRC20",
      token: "USDT",
    });
    await dispatchGetUserProfile(this.$store);
    this.loadingState.submit = false;
  }

  public closeModal(action) {
    this.isModalClosed = true;
    this.$emit("close-modal", !!this.promocode, action);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get packPrice() {
    if (this.selectedPackage) {
      return this.selectedPackage.discount_price
        ? this.selectedPackage.discount_price
        : this.selectedPackage.full_price;
    }
    return 0;
  }
}
